.searchContainer {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.searchDropdownBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  opacity: 1;
  pointer-events: "auto";
}

.searchDropdownFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  background-color: rgb(228, 228, 228);
  padding: 9.5px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  cursor: pointer;
}

.searchInput {
  width: 300px;
  height: 10px;
  padding: 20px;
  border: none;
  outline: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: rgb(245, 245, 245);
  font-size: 16px;
  color: black;
}

.searchIcon {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 20px;
  background-color: rgb(245, 245, 245);
  color: grey;
}

.searchDropdown {
  height: 50px;
  font-size: 16px;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 0px 25px 25px 0;
  position: absolute;
  background-image: -webkit-linear-gradient(
    30deg,
    rgb(47, 48, 49) 65%,
    rgb(24, 24, 24) 50%
  );
  width: 280px;
  min-height: 180px;
  left: 50%;
  top: 150px;
  transform: translateX(-50%);
  border-radius: 10px;
  padding-top: 25px;
  height: 46.5%;
  overflow-y: auto;
  cursor: pointer;
}

.searchDropdownFlex:hover {
  background-color: rgb(222, 222, 222);
}

.searchDropdown li {
  padding: 15px;
  color: rgb(245, 245, 245);
  border-radius: 5px;
}

.searchDropdown li:hover {
  background-color: rgb(68, 68, 68);
}

@media screen and (min-width: 315px) and (max-width: 650px) {
  .searchInput {
    width: 200px;
  }
}

@media screen and (max-width: 315px) {
  .searchInput {
    width: 150px;
  }
}
