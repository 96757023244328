.bodyContainer {
  max-width: 900px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.searchFlexContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.checkboxAndLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accordionContainer {
  border: 0.8px solid rgb(231, 231, 231);
  width: 100%;
}

.accordionSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #f5f8fc;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.accordionSummaryFirstChild {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.accordionSummary:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: white;
}

.accordionSummaryFirstChild:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: white;
}

.accordianImageDetailFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.emptyResults {
  font-size: 14px;
  text-align: center;
  line-height: 25px;
}

.wrongText {
  font-weight: 600;
  font-size: 20px;
}

.deleteButton {
  padding: 10px;
  background-color: white;
  cursor: pointer;
  outline: none;
  border: 1px solid grey;
  font-weight: bold;
}

.deleteButton:hover {
  background-color: rgb(245, 245, 245);
}
